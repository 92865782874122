Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorEmailEmpty = "Please enter email.";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorPasswordEmpty = "Please enter password.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Your password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Log in";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.errorMobileNotValid = "Phone not valid";
exports.placeHoldePhone = "Your phone number";
exports.builderloginAPiEndPoint = "bx_block_login/logins";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.headerImage = "assets/image_logo.png"
// Customizable Area End